import { isDefined } from "@clipboard-health/util-ts";
import { IonButton } from "@ionic/react";
import { AppV2AccountRoutes } from "@src/app/routing/constant/appV2AccountRoutes";
import { useDefinedUserId } from "@src/app/store/helperHooks";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { Shift } from "@src/appV2/Shifts/Shift/types";
import { Shift as DeprecatedShift } from "@src/lib/interface";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import { AttendancePolicyCancelShiftButton } from "./attendancePolicyCancelShiftButton";

interface ShiftActionFooterProps {
  shift: DeprecatedShift;
  shiftStateData?: ShiftStateData;
  hasMissingDocs?: boolean;
  isConfirmAttendanceVisible?: boolean;
}

export const ShiftActionFooter = (props: ShiftActionFooterProps) => {
  const {
    shift,
    shiftStateData,
    hasMissingDocs = false,
    isConfirmAttendanceVisible = false,
  } = props;

  const userId = useDefinedUserId();
  const history = useHistory();

  const isInterested = shift.interested?.includes(userId) ?? false;
  const isAssigned = shift.agentId === userId;
  const isCancelled = !(isInterested || isAssigned);

  const isShiftOngoing = moment().isAfter(shift.start);
  const onlyShowCancelButton = isShiftOngoing && shift.isInstantPay && !shift.clockInOut;

  return !isCancelled || onlyShowCancelButton || hasMissingDocs ? (
    <>
      {!shiftStateData?.metadata.isShiftOver && isDefined(shift.agent) && !shift.verified && (
        // FIXME: Move this component to use Shift from appV2
        <AttendancePolicyCancelShiftButton
          shift={shift as unknown as Shift}
          fill={shift.clockInOut || isConfirmAttendanceVisible ? "clear" : "outline"}
        />
      )}

      {hasMissingDocs && !isDefined(shift.agent) && !isShiftOngoing && (
        <IonButton
          color="secondarybtn"
          shape="round"
          onClick={() => {
            history.push(AppV2AccountRoutes.DOCUMENTS);
          }}
          expand="block"
        >
          Upload Your Documents
        </IonButton>
      )}
    </>
  ) : null;
};
