import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { Shift } from "@src/lib/interface";
import { ReactElement } from "react";

interface NfcInstructionsProps {
  shift: Shift;
}

export function NfcInstructionsDesc({ shift }: NfcInstructionsProps): ReactElement {
  const tagLocations =
    shift.nfcTagRequests
      ?.map((tagRequest) => tagRequest.tagLocation)
      .filter<string>(
        (tagLocation): tagLocation is string => isDefined(tagLocation) && tagLocation !== ""
      ) ?? [];
  const facilityName = shift.facility?.name || "The facility";

  return (
    <>
      {tagLocations.length === 0 && (
        <p>
          If you need help locating an NFC Time Clock poster at {facilityName}, please ask a
          facility admin.
        </p>
      )}
      {tagLocations.length === 1 && (
        <p>
          {facilityName} has an NFC Time Clock poster located at the following location:{" "}
          <i>{tagLocations[0]}</i>.
        </p>
      )}
      {tagLocations.length > 1 && (
        <p>
          {facilityName} has NFC Time Clock posters located at the following locations:
          <Box paddingTop={1}>
            {tagLocations.map((tagLocation, index) => (
              <Text key={tagLocation} variant="body2" paddingLeft={2}>
                {index + 1}. <i>{tagLocation}</i>
              </Text>
            ))}
          </Box>
        </p>
      )}
      <p>
        Tap{" "}
        <a
          href="https://support.clipboardhealth.com/hc/en-us/articles/7962761051799-Clocking-in-with-Clipboard-NFC-tags"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{" "}
        for NFC Time Clock instructions.
      </p>
    </>
  );
}
